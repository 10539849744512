<template>
  <div class="text-center mb-10">
    <h1 class="mb-3">
      {{ $t("PasswordChange.Title", {}, { locale: this.activeLanguage }) }}
    </h1>
    <div class="text-gray-400 fw-bold fs-4">
      {{
        $t("PasswordChange.Description", {}, { locale: this.activeLanguage })
      }}
    </div>
  </div>
  <form
    class="form w-100 needs-validation"
    @submit="onSubmitPasswordChange"
    id="passwordChangeForm"
    novalidate
    v-if="!isTokenUsed"
  >
    <div class="mb-3 fv-row" data-sx-password-meter="true">
      <div>
        <label class="form-label fw-bolder fs-6 required" for="password">
          {{
            $t("PasswordChange.Password", {}, { locale: this.activeLanguage })
          }}
        </label>
        <div class="position-relative mb-3">
          <input
            class="form-control form-control-lg"
            type="password"
            placeholder=""
            name="password"
            id="password"
            required
            autocomplete="off"
            data-autofocus="true"
            v-model="userData.password"
            @keyup="validatePassword"
            @keyup.enter="onSubmitPasswordChange"
          />
          <div class="invalid-tooltip">
            {{
              this.$t(
                "FieldWarnings.TextNotValid",
                {},
                { locale: this.activeLanguage }
              )
            }}
          </div>
        </div>
        <div
          class="d-flex align-items-center mb-3"
          data-sx-password-meter-control="highlight"
        >
          <div
            class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
          ></div>
          <div
            class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
          ></div>
          <div
            class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
          ></div>
          <div
            class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
          ></div>
        </div>
      </div>
    </div>
    <div class="fv-row mb-5">
      <label
        class="form-label fw-bolder fs-6 required"
        for="password_confirmation"
        >{{
          $t(
            "PasswordChange.ConfirmPassword",
            {},
            { locale: this.activeLanguage }
          )
        }}
      </label>
      <div class="position-relative">
        <input
          class="form-control form-control-lg"
          type="password"
          placeholder=""
          name="password_confirmation"
          id="password_confirmation"
          autocomplete="off"
          v-model="passwordConfirmation"
          @keyup="validatePassword"
          @keyup.enter="onSubmitPasswordChange"
          required
        />
        <div class="invalid-tooltip">
          {{
            this.$t(
              String.isNullOrWhiteSpace(passwordConfirmation)
                ? "FieldWarnings.TextNotValid"
                : "PasswordChange.PasswordDontMatch",
              {},
              { locale: this.activeLanguage }
            )
          }}
        </div>
      </div>
    </div>
    <div class="row">
      <template v-if="formPosting">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-md-6">
          <button
            @click="onSubmitPasswordChange"
            type="button"
            class="btn btn-lg btn-primary w-100 mb-2"
          >
            <span class="indicator-label">
              {{
                $t("PasswordChange.Title", {}, { locale: this.activeLanguage })
              }}
            </span>
          </button>
        </div>
        <div class="col-md-6">
          <router-link
            to="/sign-in"
            class="btn btn-lg btn-light fw-bolder w-100"
            >{{
              $t("BaseModelFields.Cancel", {}, { locale: this.activeLanguage })
            }}</router-link
          >
        </div>
      </template>
    </div>
  </form>
  <template v-else>
    <div class="alert alert-warning mb-0 fs-5">
      {{
        $t(
          "PasswordChange.FormHiddenMessage",
          {},
          { locale: this.activeLanguage }
        )
      }}
    </div>
    <router-link to="/sign-in" class="btn btn-success mt-2 w-100">
      <i class="fa fa-lock fs-5"></i>
      {{
        this.$t("BaseModelFields.Login", {}, { locale: this.activeLanguage })
      }}
    </router-link>
  </template>
</template>
<script>
import { PasswordMeterComponent } from "@/assets/ts/components";
import { onMounted, nextTick } from "vue";
import $ from "jquery";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default {
  name: "PasswordChange",
  data() {
    return {
      userData: {
        clientUrl: `${window.location.origin}/#/`,
        token: encodeURIComponent(this.$route.query.token.replaceAll(" ", "+")),
        password: null,
      },
      passwordConfirmation: null,
      formPosting: false,
      passwordDontMatch: false,
      isTokenUsed: false,
      activeLanguage: localStorage.getItem("activeLang") || this.activeLanguage,
    };
  },
  watch: {
    "$store.state.activeLang"() {
      this.activeLanguage = this.$store.state.activeLang;
      this.changeDocumentTitle();
    },
  },
  mounted() {
    let input = document.querySelector("[data-autofocus='true']");
    if (input) {
      input.focus();
    }

    if (String.isNullOrWhiteSpace(this.userData.token)) {
      this.$router.push({
        path: "/sign-in",
      });
    }
  },
  components: {},
  methods: {
    changeDocumentTitle() {
      this.$root.changeDocumentTitle(
        this.$t("PasswordChange.Title", {}, { locale: this.activeLanguage })
      );
    },
    validatePassword() {
      this.passwordDontMatch =
        this.userData.password !== this.passwordConfirmation;
      if (this.passwordDontMatch) {
        $("#password_confirmation").addClass("is-invalid");
        $("#password_confirmation").removeClass("is-valid");
      } else {
        $("#password_confirmation").removeClass("is-invalid");
        $("#password_confirmation").addClass("is-valid");
      }
    },
    onSubmitPasswordChange(event) {
      var form = $("form#passwordChangeForm"),
        self = this;
      form.addClass("was-validated");
      event.stopPropagation();
      event.preventDefault();
      this.isTokenUsed = false;

      if (
        !form[0].checkValidity() ||
        String.isNullOrWhiteSpace(this.userData.password) ||
        String.isNullOrWhiteSpace(this.passwordConfirmation) ||
        this.passwordDontMatch ||
        this.formPosting
      ) {
        if (this.passwordDontMatch) {
          this.validatePassword();
        }
        return;
      }

      this.formPosting = true;
      this.$authAxios
        .post("/PasswordChange", {
          clientUrl: this.userData.clientUrl,
          token: this.userData.token,
          password: this.$root.encrypt(this.userData.password, true),
        })
        .then((result) => {
          this.formPosting = false;
          var response = result.data,
            message = response.msg;

          if (response.isOk) {
            message = this.$t(
              "PasswordChange.SuccessMessage",
              {},
              { locale: this.$store.state.activeLang }
            );
          } else {
            if (response.isPasswordSame) {
              message = this.$t(
                "PasswordChange.SamePasswordFound",
                {},
                { locale: this.$store.state.activeLang }
              ).replaceAll(
                "PAST_PASSWORD_COUNT",
                response.pastPasswordCheckCount
              );
            } else {
              message = this.$root.getMessageByKey(message);
            }
          }

          this.isTokenUsed = response.isOk;

          Swal.fire({
            html: `<span class='fw-bold fs-5'>${message}</span>`,
            icon: response.isOk ? "success" : "error",
            buttonsStyling: false,
            showDenyButton: response.isOk,
            denyButtonText:
              "<i class='fa fa-lock fs-5'></i> " +
              this.$t(
                "BaseModelFields.Login",
                {},
                { locale: this.activeLanguage }
              ),
            confirmButtonText:
              "<i class='fa fa-times'></i>" +
              this.$t(
                "BaseModelFields.Close",
                {},
                { locale: this.activeLanguage }
              ),
            customClass: {
              confirmButton: "btn fw-bold btn-success",
              denyButton: "btn fw-bold btn-info me-2",
            },
            reverseButtons: true,
          }).then((result) => {
            if (result.isDenied) {
              self.$router.push({ name: "sign-in" });
            }
          });
        })
        .catch((response) => {
          this.formPosting = false;
          var errorMessage = this.$t(
            "BaseModelFields.UnexpectedErrorHasOccurred",
            {},
            { locale: this.activeLanguage }
          );
          Swal.fire({
            html: `<span class='fw-bold fs-5'>${errorMessage}</span>`,
            icon: "error",
            showDenyButton: true,
            denyButtonText:
              "<i class='fa fa-users fs-5'></i> " +
              this.$t(
                "BaseModelFields.SupportTeam",
                {},
                { locale: this.activeLanguage }
              ),
            buttonsStyling: false,
            confirmButtonText: this.$t(
              "BaseModelFields.TryAgain",
              {},
              { locale: this.activeLanguage }
            ),
            customClass: {
              confirmButton: "btn fw-bold btn-success",
              denyButton: "btn fw-bold btn-info me-2",
            },
            reverseButtons: true,
          }).then((result) => {
            if (result.isDenied) {
              window.open(this.$config.webSites.support);
            }
          });
        });
    },
  },
  created() {
    this.changeDocumentTitle();
  },
  setup() {
    onMounted(() => {
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });
  },
};
</script>
